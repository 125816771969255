<template>
  <div class="price-list-proposal-list-view">
    <v-row>
      <v-col class="hidden-sm-and-down" cols="0" md="3">
        <SsoUserMenu v-if="isSsoAuthenticated" class="menu-navigator" />
        <v-divider></v-divider>
        <MenuNavigator class="menu-navigator" />
      </v-col>
      <v-col cols="12" md="9">
        <ProfileTitle :category="category" />

        <PriceListList
          class="price-list-proposal-list"
          :isPriceListProposal="true"
        />
      </v-col>
    </v-row>
  </div>
</template>
<style lang="scss">
.price-list-proposal-list-view {
  .price-list-list {
    .price-list-list-item {
      min-height: 70px;
      &.v-list-item:nth-child(odd) {
        background: $card-light-background;
      }
    }
  }
}
</style>
<script>
import MenuNavigator from "@/components/profile/MenuNavigator";
import ProfileTitle from "@/components/profile/ProfileTitle";
import PriceListList from "@/components/priceList/PriceListList.vue";

import categoryMixin from "~/mixins/category";
import { mapGetters } from "vuex";
import SsoUserMenu from "@/components/profile/SsoUserMenu.vue";

export default {
  name: "PriceListProposalListView",
  mixins: [categoryMixin],
  components: {
    MenuNavigator,
    ProfileTitle,
    PriceListList,
    SsoUserMenu
  },
  data() {
    return {};
  },
  computed: {
    ...mapGetters({
      isSsoAuthenticated: "cart/isSsoAuthenticated"
    })
  },
  methods: {}
};
</script>
