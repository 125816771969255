<template>
  <v-row class="price-list-list-row">
    <v-col cols="4">
      {{ priceList.name }}
    </v-col>
    <v-col cols="2">
      <PriceListProposalStatus
        v-if="
          publicationState &&
            publicationState.publicationStateId !== null &&
            publicationState.publicationStateId !== undefined
        "
        :publicationState="publicationState"
      />
      <PriceListProposalStatus v-else :status="status" />
    </v-col>
    <v-col cols="2">
      {{ $dayjs(priceList.fromDate).format("DD-MM-YYYY") }}
    </v-col>
    <v-col cols="2">
      {{ $dayjs(priceList.toDate).format("DD-MM-YYYY") }}
    </v-col>
    <v-col cols="2">
      {{ priceList.priceListId }}
    </v-col>
  </v-row>
</template>
<style lang="scss"></style>
<script>
import PriceListProposalStatus from "@/components/priceList/PriceListProposalStatus.vue";

export default {
  name: "PriceListListRow",
  components: {
    PriceListProposalStatus
  },
  props: {
    priceList: { type: Object, required: true },
    publicationState: { type: Object, required: false },
    status: { type: String, required: false }
  },
  data() {
    return {};
  },
  computed: {},
  methods: {}
};
</script>
